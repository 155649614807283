import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/PageLayout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const CLHbid = makeShortcode("CLHbid");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Starting Bids`}</h1>
    <p>{`The designated Starting Bid for each Parcel is a number set by `}<CLHbid mdxType="CLHbid" />{` and the Seller prior to each Auction being posted online. The Starting Bid number is the minimum amount that bidding may commence at for that specific Parcel. The first bid will be the Starting Bid number.`}</p>
    <p>{`If a Starting Bid is not obtained the Parcel will not sell. The Starting Bid is not what is commonly known as a Reserve Bid. The Starting Bid is set at what is perceived as a discounted amount from what the current Fair Market Value (‘FMV’) of the Parcel has recently been valued at and what the Parcel is likely to sell for on Auction Day. The Starting Bid doesn’t in any way purport to be FMV.`}</p>
    <p>{`The Starting Bid is meant in part to encourage only serious bidders to bid and hopefully speed up the entire bidding process. The Starting Bid number may be adjusted prior to Auction day so potential bidders are encouraged to check the website for any changes prior to the commencement of the Auction.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      